#button-toggle-theme {
    position: relative;
    background: transparent;
    border: 1px solid var(--common-color);
    width: 40px;
    height: 22px;
    cursor: pointer;
    padding: 0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin-top: 0;
    transform: scale(1.3);
  }

#moon {
  position: absolute;
  top: -2px;
  left: 0;
  transition: left 0.25s ease-in-out;
}  
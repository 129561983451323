.container {
    font-family: 'Roboto', sans-serif;
    width: 250px;
    height: 400px;
    background-color: #2e2e2e;
    border-radius: 15px;
    box-shadow: inset 0px 0px 5px var(--primary-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.06);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    
}

.container img {
    max-width: 55%;
    max-height: 50%;
    border-radius: 50%;
    margin-top: 20px;
    border: 6px solid var(--primary-color);
  }



.certified {
    color: rgb(0, 212, 0);
  }

.contact {
    margin-top: 20px;
}
  
button:hover {
    background: linear-gradient(to bottom right, #3e8e41, #2E8B57);
}
  
ul.footer_container {
    position: relative;
    display: flex;
    /* box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.8); */
    padding: 0;
}

.footer_buttons {
    list-style: none;
    font-size: 16px;
    padding: 10px 20px;
}

.footer_buttons:nth-child(1){
    border-right: 1px solid rgb(61, 61, 61);
    padding-right: 10px;
}

ul > li.footer_buttons > i {
    margin-right: 0.5em;
    color: orange;
  }

.footer_name {
    font-size: 16px;
    color: var(--common-color);
    font-weight: 600;
    letter-spacing: 0.7px;
    text-transform: capitalize;
}  
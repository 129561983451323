
/* * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
} */

:root {
  --common-color: #dadada;
  --primary-color: #ffa230;
  --secondary-color: #8cdcfe;
  --tertiary-color: #75ce85;
  --background-color: #1a1a1a;
  --font-size: calc(10px + 2vmin);
  --label: #f0f0f0;
  --primary-text: #d5d5d5;
  --secondary-text: #c0c0c0;
  --border-bottom: #2b2b2b;
  --box-shadow-1: #050505;
  --box-shadow-2: #2c2c2c;
  --gradient-1: #00bfff;
  --gradient-2: #007fff;
  --gradient-3: #004080; 
}

[data-theme='light'] {
  --common-color: #1a1a1a;
  --primary-color: #308DFF;
  --secondary-color: #FEAE8C;
  --tertiary-color: #CE75BE;
  --background-color: #f5f5f5;
  --background-color-2: #fcfcfc;
  --label: black;
  --primary-text: black;
  --secondary-text: rgb(19, 19, 19);
  --border-bottom: #cccccc;
  --box-shadow-1: #cfcfcf;
  --box-shadow-2: #ffffff;
  --gradient-1: #00bfff;
  --gradient-2: #007fff;
  --gradient-3: #004080; 
}

header {
  min-height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  color: var(--common-color);
  font-size: calc(10px + 2vmin);
}

a {
  text-decoration: none;
  color: inherit
}

button {
  background: linear-gradient(to bottom right, #4CAF50, #2E8B57);
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 1px;
}

code {
  font-family: 'Courier New', Courier, monospace;
  font-size: 1em;
  background-color: #000000;
  color: white;
  padding: 0.5em;
  border: 1px solid #fffc53;
}

.baseline{
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  padding-top: 71px;
  padding-bottom: 51px;

}

.primary-color {
  color: var(--primary-color);
}

.secondary-color {
  color: var(--secondary-color);
}

.tertiary-color {
  color: var(--tertiary-color);
}

.primary-text {
  font-size: 16px;
  margin: 4px;
  padding: 10px;
  color: var(--primary-text);
  font-weight: 600;
  letter-spacing: 0.7px;
  text-transform: capitalize;
}  

.secondary-text {
  color: var(--secondary-text);
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.8px;
}

.warning {
  background-color: var(--background-color-2);
  box-shadow: inset 3px 3px 3px var(--box-shadow-1), inset -3px -3px 3px var(--box-shadow-2);
  border-radius: 10px;
  padding: 5px;
  padding-left: 10px;
  color: rgb(235, 122, 122);
}

.tip {
  background-color: var(--background-color-2);
  box-shadow: inset 3px 3px 3px var(--box-shadow-1), inset -3px -3px 3px var(--box-shadow-2);
  border-radius: 10px;
  padding: 5px;
  padding-left: 10px;
  color: rgb(15, 185, 0);
  margin-top: 0;
}
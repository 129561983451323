.footer {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: var(--background-color);
    color: var(--common-color);
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-end;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    border-top: 1px solid var(--border-bottom);
    z-index: 9999;
  }
  
.introduction-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    backdrop-filter: blur(20px);
    /* border: 2px solid rgba(255,255,255,0.2); */
    border-radius: 10px;
    padding: 0 50px 20px 50px;
    box-shadow: 0 0 5px var(--primary-color);
    background-color: var(--background-color-2);
}


#call-to-action-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  column-gap: 10rem;
  flex-direction: row-reverse;

}

#web-dev-future{
  display: flex;
  flex-direction: column;
  width: 33%;
  padding: 20px;
  margin-right: 20px;
  align-items: center;
}

#web-dev-future h2 {
  margin: 0;
  text-align: center;
  line-height: 1.2em;
}

#web-dev-future p {
  font-size: 24px;  
  margin: 0.5rem 0;
  width: 85%;
}

#web-dev-future p + h2  {
  margin-top: 32px;
}

#web-developer-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 520px;
  height: 520px;  
}

#web-developer-image {
  width: 500px;
  height: 500px;  
  border-radius: 20px;  
}

.navigation {
    position: fixed;
    list-style: none;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    padding: 10px 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid var(--border-bottom);
    z-index: 9999;
    
}
  
.nav-logo {
    display: inline-flex;
    font-size: 18px;
    left: 0;
    position: absolute;
    padding: 20px;
    color: var(--common-color);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.menu-list-node {
    width: 125px;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 18px;
    position: relative;   
    transition: color 0.3s ease;
}

.menu-list-node:hover {
  color: var(--primary-color);
}

.menu-list-node i {
  margin-right: 0.5em;
}
  
a {
  position: relative;
}

a.active {
  color: var(--primary-color);
  font-weight: bold;
}  

a::after {
  content: "";
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 0%;
  height: 5px;
  background: linear-gradient(135deg, var(--gradient-1), var(--gradient-2), var(--gradient-3));    
  text-underline-offset: 10px;
  border-radius: 5px;
  transition: width 1.2s ease-in-out, left 1.2s ease-in-out;
  
}

a.active::after {
  width: 70%;
  left: 15%
}

.toggle-theme {
  display: inline-flex;
  font-size: 18px;
  padding: 20px;
  color: #b8b8b8;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

/* Mobile view styles */
@media (max-width: 768px) {

}
.project-container {
    width: 51%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: 18px;
    /* border: 2px solid white; */
    margin: 20px 0;
}

.img-container {
    perspective: 1000px;

    & img {
        box-shadow: 0 5px 20px var(--primary-color);
        border: 2px solid black;
        border-radius: 5px;
        transform: rotateY(-25deg) rotateX(15deg) scale(1);
        transition: transform 0.3s ease-in-out;
        width: 300px;
    }
    
        & :hover {
            transform: rotateY(0deg) rotateX(0deg) scale(1.0);
        }
}


.project-content {
    display: flex;
    flex-direction: column;
    padding: 10px;

    & p {
        padding: 0 20px 0 0;
    }

    & h5 {
        margin: 0;
    }
}

.project-header-and-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;

    & a > button {
        background: linear-gradient(to bottom right, #4CAF50, #2E8B57);
        color: white;
        padding: 8px 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        text-transform: capitalize;
        letter-spacing: 1px;
    }
}


.zera-games-container {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 18px;
    padding-top: 100px;
}

code {
    width: 300px;
}
body {
    background-color: var(--background-color);
}

.color {
    color: var(--tertiary-color);
}

h3, h4 {
    margin: 0;
}

i {
    margin-right: 0.5em;
}

.work-date {
    font-size: calc(10px + 1vmin);
}

ul {
    padding-left: 20px;
}

.brown {
    color: brown;
}

.align-right {
    display: flex;
    align-items: flex-end;
}

.skills-container {
    display: flex;

}

.skills-mini-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 175px;
}

.h3-skills {
    padding-left: 20px;
}

.resume-container {
    display: flex;

}

.menu {
    position: fixed;
    top: 33%;
    left: 0;
    width: 175px;
    padding: 20px;
    overflow-y: auto;
  }

.menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}  

.menu li {
    font-weight: 500;
    font-size: medium;
    margin-bottom: 2px;
    cursor: pointer;
    padding: 6px;
    padding-left: 10px;
    user-select: none;
}

.menu li:hover {
    color: var(--primary-color);
    transition: all 0.3s ease;
    
}

.menu li.active {
    background-color: var(--background-color);
    box-shadow: inset 3px 3px 3px var(--box-shadow-1), inset -3px -3px 3px var(--box-shadow-2);
    border-radius: 10px;
    color: var(--primary-color);
  }
  
p.no-margin {
    margin: 0;
    padding: 0;
}

p.top {
    margin-top: 5px;
}

#resume-languages {
    margin-bottom: 10px;
}

.course-date {
    margin-top: 20px;
}

.reference-table {
    padding: 10px;
}

.reference-table thead > tr {
    display: flex;
}
.reference-table thead > tr > th {
    width: 100px;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
}

.reference-table tbody > tr {
    display: flex;
}

.reference-table tbody > tr > td {
    width: 100px;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
}
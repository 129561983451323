#contact-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    margin: 0 auto;
}

label {
    display: inline-block;
    margin: 10px 0 -5px 0;
    color: var(--label); /* Light gray color for better visibility on dark background */
    font-weight: normal; /* Resetting the font-weight to avoid all labels being bold */
}

form {
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 24px;
    padding: 20px;
    background-color: var(--background-color);
    /* box-shadow: inset 3px 3px 3px #050505, inset -3px -3px 3px #2c2c2c; */
    border-radius: 12px; /* Rounded corners for the form */
}

input[type="text"],
input[type="email"],
input[type="tel"] {
    width: 100%;
    padding: 12px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 4px; /* Rounded corners for the inputs */
}

input[type="submit"] {
    margin-top: 5px;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    background-color: #28a745;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
}

input[type="submit"]:hover {
    background-color: #218838; /* Darker green for a hover effect on the submit button */
}

textarea[type="textarea"] {
    height: 100px; /* Set a fixed height */
    resize: vertical; /* Allow vertical resizing */
    width: 100%;
    padding: 12px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 4px; /* Rounded corners for the inputs */
}

.formDivider {
    display: flex;
    justify-content: space-between; /* This will ensure that the items are evenly spaced */
    flex-direction: column;
}

.formDividerMessage {
    width: 100%
}

.formGroup {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.formLabel {
    color: var(--primary-color);
}